<template>
  <v-row class="pb-4">
    <div>
      <v-checkbox
        color="teal lighten-2"
        class="mt-10 ml-3"
        :input-value="selected.includes(item.id)"
        :disabled="disabledView"
        @change="select"
      />
    </div>
    <v-col
      :id="block_id"
      v-click-outside="triggerOnFocus"
      :class="{'blocked-box': disabledView}"
    >
      <v-row>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Марка автомобиля
          </label>
          <v-autocomplete
            v-model="item.transport_mark_code"
            item-text="name"
            item-value="code"
            :items="transportMarks"
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
            :filter="filterBySearchField"
            @change="updateMarkName"
          />
        </v-col>
        <v-col
          cols="1"
          class="pr-0"
        >
          <label>
            Модель
          </label>
          <v-text-field
            v-model="item.model_name"
            outlined
            dense
            hide-details="auto"
            background-color="#FFFFFF"
          />
        </v-col>
        <v-col>
          <label>Тип</label>
          <v-radio-group
            v-model="item.vin_type"
            hide-details
            @change="readyToUpdate"
          >
            <v-radio
              v-for="(type,idx) in vinTypes"
              :key="`type-${idx}`"
              :label="type.name"
              :value="type.value"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            VIN
          </label>
          <v-text-field
            v-model="item.vin"
            v-mask="mask"
            outlined
            dense
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Дата выпуска
          </label>
          <custom-date-picker v-model="item.release_date" />
        </v-col>
        <v-col
          cols="2"
        >
          <label>Объем (см3)</label>
          <input-numeric
            v-model="item.volume"
            class=""
            :fraction="0"
          />
        </v-col>
      </v-row>
    </v-col>
    <div class="actions">
      <v-btn
        text
        min-width="40px"
        width="40px"
        @click="search"
      >
        <v-icon>
          mdi-magnify
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="disabledView"
        text
        min-width="40px"
        width="40px"
        @click="removeTransportDetail"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </div>
  </v-row>
</template>
<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {readyToUpdateInjector} from "@/helpers/injectors";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {mapGetters} from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {getTransportMarkNameByCode} from "@/helpers/catalogs";
import {googleImage} from "@/helpers/text";
export default {
  components:{InputNumeric, CustomDatePicker},
  mixins:[blockAutoUpdateMixin, filterBySearchFieldMixin],
  provide(){
    return {
      [readyToUpdateInjector]:this.readyToUpdate
    }
  },
  props:{
    transport:{
      type:Object,
      required:true
    },
    index:{
      type:Number,
      required:true
    },
    transports:{
      type:Array,
      required:true
    },
    selected:{
      type:Array,
      required:true
    }
  },
  data(){
    return{
      test:true,
      block_id:null,
      vinTypes:[
        {
          name:"VIN",
          value:1
        },
        {
          name:"Шасси",
          value:2
        },
        {
          name:"Кузов",
          value:3
        }
      ],
      item:{
        id:null,
        ware_id:null,
        transport_mark_code:null,
        transport_mark_name:null,
        model_name:null,
        vin:null,
        vin_type:null,
        release_date:null,
        volume:null,
        sort_index:null,
      },
      mask:{
        mask: 'CCCCCCCCCCCCCCCCC',
        tokens: {
          'C': {
            pattern: /(?![QIOqio])[0-9a-zA-Z]/,
            transform: function(v) {
              return v.toLocaleUpperCase();
            }
          }
        }
      },
    }
  },
  computed:{
    ...mapGetters({
      transportMarks: "dt/getNsiTransportMarks",
      measureUnits:"catalogs/getNsiMeasureUnits",
      disabledView:'dt/getVisibility'
    }),
  },
  watch:{
    transport:{
      handler(){
        this.setFields()
        this.setBlockId()
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    updateMarkName(){
      this.item.transport_mark_name = getTransportMarkNameByCode(this.transportMarks, this.item.transport_mark_code)
    },
    getSearchString(){
      const prepared = this.item.pick(['transport_mark_name','model_name'])
      return Object.values(prepared).join(' ')
    },
    search(){
      googleImage(this.getSearchString())
    },
    select(){
      this.$emit('select', this.item.id)
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    setBlockId(){
      this.block_id = `dt-transport-detail-${this.index}`
    },
    setFields(){
      Object.keys(this.item).forEach(key => {
        if(key in this.transport){
          this.item[key] = this.transport[key]
        }
      })
    },
    addTransportDetail(){
      this.$emit('add', this.transport.sort_index)
    },
    removeTransportDetail(){
      this.$emit('remove', {id: this.transport.id, index: this.index })
    },
    uploadData(){
      const payload = convertEmptyStringsToNull(this.item)
      return this.$store.dispatch('dt/updateTransportDetails', payload).then((res) => {
        const [item] = res.data
        this.$emit('update', {item, index: this.index})
        return res
      })
    }
  }
}
</script>
<style scoped>
.actions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  padding: 0 10px;
  border-left: 1px dotted grey;
  width: 50px;
}
</style>
