<template>
  <div>
    <group-title
      v-if="items.length"
      :selected-items="selectedItems"
      :items="items"
      @select="selectAll"
    />
    <transport-details-item
      v-for="(transport, index) in items"
      :key="index"
      :transport="transport"
      :index="index"
      :transports="items"
      :selected="selectedItems"
      @add="addTransportDetail"
      @remove="removeTransportDetail"
      @update="updateTransportDetail"
      @select="selectElement"
    />
  </div>
</template>
<script>
import TransportDetailsItem from "@/components/dt/goods/ware-details/transport-details-item.vue";
import GroupTitle from "@/components/dt/goods/ware-details/group-title.vue";
import wareDetailGroupSelectMixin from "@/mixins/ware-detail-group-select.mixin";

export default {
  components: {GroupTitle, TransportDetailsItem},
  mixins:[wareDetailGroupSelectMixin],
  props:{
    wareId:{
      required:true,
      type:Number
    },
    items:{
      required:true,
      type:Array
    }
  },
  methods:{
    updateTransportDetail({index, item}){
      this.$emit("update", {type:"transport", item, index})
    },
    addTransportDetail(lastWareSortIndex){
      const sortIndex = lastWareSortIndex + 1
      this.$store.dispatch('dt/createTransportDetails', {
        wareId: this.wareId,
        sortIndex
      }).then(res => {
        this.$emit("add", {type:"transport", item:res.data})
      }).catch(() => this.$error())
    },
    removeTransportDetail({id, index}){
      this.$store.dispatch('dt/deleteTransportDetails', {id}).then(() => {
        this.clearSelected()
        this.$emit("delete", {type:"transport", index})
      }).catch(() => this.$error())
    }
  }
}

</script>
